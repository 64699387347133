<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <div class="mb15" >
        <span class='mr15' v-if="max<5">
          <a-button type="primary" v-has:agentLevelAdd="()=> {$router.push('/agent/agent_level/detail?type=add&max='+max)}">
            <PlusOutlined />添加代理商级别
          </a-button>
        </span>
      </div>
      <a-table :pagination='false' bordered rowKey='id' :columns='columns' :data-source='data' :loading='listLoading'>
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined /> </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:agentLevelEdit="()=> {$router.push('/agent/agent_level/detail?type=edit&id='+record.id)}" href="javascript:;">修改</a>
                </a-menu-item>
                <a-menu-item>
                  <a v-has:agentLevelDel="()=>agentLevelDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
    </a-card>
  </a-layout>
</template>
<script>
  import { DownOutlined,PlusOutlined } from '@ant-design/icons-vue';
  import { ref, onMounted, inject, reactive, toRefs } from 'vue';
  import { getAgentLevelList, setAgentLevelDel,getAgentMax } from "@/api/agent";
  import { message } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  // 初始化默认筛选项数值
  let defSearch = {
    page: 1,
    limit: 10
  };
  export default {
    components: { DownOutlined,PlusOutlined },
    setup(props) {
      // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      const state = reactive({
        listLoading: false
      });
      let data = ref([])
      let columns = [{
        title: '级别',
        dataIndex: 'level_num',
        key: 'level_num',
      },
      {
        title: '级别名称',
        dataIndex: 'level_name',
        key: 'level_name',
      },
      {
        title: '开户折扣',
        dataIndex: 'discount',
        key: 'discount',
      },
      {
        title: '续费折扣',
        dataIndex: 'renew_discount',
        key: 'renew_discount',
      },
      {
        title: '是否OEM',
        dataIndex: 'is_oem_text',
        key: 'is_oem_text',
      },
      {
        title: '自定义产品价格',
        dataIndex: 'is_prouduct_price_text',
        key: 'is_prouduct_price_text',
      },
      {
        title: '操作',
        width: 100,
        key: 'action',
        slots: { customRender: 'action' },
      },
      ]
      const $router = inject('$router')
      const $Modal = inject('$Modal')
      //删除代理商
      const agentLevelDel = (e) => {
        $Modal.confirm({
          content: '删除后该等级将无法恢复，您确定要删除该等级吗？',
          centered:true, 
          onOk: async () => {
            let res = await setAgentLevelDel(e.id).then(res => res.data);
            if ($iscode(res)) {
              message.success(res.msg);
              initData(search.value);
              getMax()
            } else {
              message.error(res.msg);
            }
          }
        })
      }
      // 代理商列表获取
      const initData = async (values) => {
        state.listLoading = true;
        try {
          let res = await getAgentLevelList(values).then(res => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      const max=ref(0)
      const getMax=async ()=>{
        let maxLength=await getAgentMax().then(res=>res.data).catch(error=>error)
        max.value=maxLength.data.max_level_num
      }
      getMax()
      onMounted(() => {
        initData(search.value);
      })
      return {
        ...toRefs(state),
        search,
        data,
        columns,
        agentLevelDel,
        max
      }
    }
  }
</script>